//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$red-color: red;
$dark-red-color: #e10707;
$black: #363839;
$white: #ffffff;
$grey: #a2a0a0;
$light-grey: #dadada;
//Font-sizes
$ultra_small-size: 11px;
$small-size: 13px;
$small-middle-size: 14px;
$middle-size: 15px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.container {
  max-width: 1230px;
  margin: 0 auto;
  padding: 0 30px;

  @media (max-width: 1230px) {
    max-width: 940px;
  }

  @media (max-width: 980px) {
    max-width: 767px;
  }
}
