//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$red-color: red;
$dark-red-color: #e10707;
$black: #363839;
$white: #ffffff;
$grey: #a2a0a0;
$light-grey: #dadada;
//Font-sizes
$ultra_small-size: 11px;
$small-size: 13px;
$small-middle-size: 14px;
$middle-size: 15px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.grid {
  @media (max-width: 860px) {
    display: none;
  }
}

.link {
  color: $black;
  transition: .2s linear;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 16px;
  font-style: normal;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 3px;
    background: $dark-red-color;
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translate(-50%);
    opacity: 0;
    transition: .2s linear;
  }

  &:hover {
    color: $dark-red-color;
    &:before {
      opacity: 1;
    }
  }
  &:not(:last-child) {
    margin-right: 23px;
  }
}

.navigation {
  display: flex;
  justify-content: flex-end;
}
