//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$red-color: red;
$dark-red-color: #e10707;
$black: #363839;
$white: #ffffff;
$grey: #a2a0a0;
$light-grey: #dadada;
//Font-sizes
$ultra_small-size: 11px;
$small-size: 13px;
$small-middle-size: 14px;
$middle-size: 15px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.InputLabel {
  display: flex;
  align-items: center;
  font-size: $small-middle-size;
  color: $primary-color;
  word-break: break-word;
}

.mark {
  cursor: pointer;
  border: 1px solid black;
  border-radius: 50%;
  padding: 3px 4px;
  margin-left: 3px;
  width: 11px;
}

.height {
  height: 38px
}
