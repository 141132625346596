//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$red-color: red;
$dark-red-color: #e10707;
$black: #363839;
$white: #ffffff;
$grey: #a2a0a0;
$light-grey: #dadada;
//Font-sizes
$ultra_small-size: 11px;
$small-size: 13px;
$small-middle-size: 14px;
$middle-size: 15px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.hamburger {
  display: flex;
  align-items: center;
  padding: 6px 13px;
  border-left: 1px solid $light-grey;
  img {
    width: 25px;
  }
}

.main {
  display: flex;
  justify-content: space-between;
  border: 1px solid $light-grey;
  align-items: center;
  cursor: pointer;
  background: #f9f9f9;
  p {
    padding: 0;
    margin: 0 0 0 12px;
    font-size: 12px;
  }
}

.grid {
  display: none;
  @media (max-width: 860px) {
    display: grid;
  }
}

.logo {
    margin: 0 auto 20px;
}

.menu {
  padding: 20px;
}

.navigation {
  display: grid;
  overflow: hidden;
  transition: .4s linear;
  height: 0;
}

.open {
  height: 290px;
}

.link {
  border: 1px solid $light-grey;
  font-size: 12px;
  padding: 10px 12px;
  background: #f9f9f9;
  &:not(:last-child) {
    border-bottom: 0;
  }
}
