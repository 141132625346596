//Colors
$primary-color: #111111;
$secondary-color: #4A4A4A;
$additional-color: #EA8226;
$negative-color: #d0021b;
$white-color: white;
$red-color: red;
$dark-red-color: #e10707;
$black: #363839;
$white: #ffffff;
$grey: #a2a0a0;
$light-grey: #dadada;
//Font-sizes
$ultra_small-size: 11px;
$small-size: 13px;
$small-middle-size: 14px;
$middle-size: 15px;
$big-size: 17px;

//Border-radius
$small-radius: 4px;


.footer {
  background: $black;
  color: $white;
  padding: 30px 0;
}

.grid {
  display: flex;
  justify-content: space-between;

  @media (max-width: 980px) {
    flex-direction: column;
  }
}

.content {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  color: $grey;
  font-style: normal;
}
.link {
  cursor: pointer;
  transition: .2s linear;
  &:hover {
    color: $dark-red-color;
  }
}

.twitter {
  display: block;
  border-radius: 50%;
  background: $white;
  width: 70px;
  border: 1px solid #333333;
  margin-top: 14px;
}

.partner {
  display: block;
  cursor: pointer;
  margin-bottom: 15px;
  img {
    width: 213px;
  }
}
